/**
 * @generated SignedSource<<4d7ce1caaea0e5a6749a945f4db234d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetConciergeAgentAvailabilityInput = {
  accountId: string;
  isAvailable: boolean;
};
export type SetConciergeAgentChatAvailabilityMutation$variables = {
  input: SetConciergeAgentAvailabilityInput;
};
export type SetConciergeAgentChatAvailabilityMutation$data = {
  readonly setConciergeAgentChatAvailability: {
    readonly account: {
      readonly conciergeAgent: {
        readonly chats: ReadonlyArray<{
          readonly user: {
            readonly id: string;
          } | null;
        }>;
        readonly isAvailable: boolean;
        readonly isEnrolledIntoChats: boolean;
        readonly " $fragmentSpreads": FragmentRefs<"ConciergeAgentDetailsFragment_conciergeAgent">;
      } | null;
    } | null;
  } | null;
};
export type SetConciergeAgentChatAvailabilityMutation = {
  response: SetConciergeAgentChatAvailabilityMutation$data;
  variables: SetConciergeAgentChatAvailabilityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAvailable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEnrolledIntoChats",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetConciergeAgentChatAvailabilityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetConciergeAgentAvailabilityPayload",
        "kind": "LinkedField",
        "name": "setConciergeAgentChatAvailability",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConciergeAgent",
                "kind": "LinkedField",
                "name": "conciergeAgent",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConciergeChat",
                    "kind": "LinkedField",
                    "name": "chats",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ConciergeAgentDetailsFragment_conciergeAgent"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetConciergeAgentChatAvailabilityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetConciergeAgentAvailabilityPayload",
        "kind": "LinkedField",
        "name": "setConciergeAgentChatAvailability",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConciergeAgent",
                "kind": "LinkedField",
                "name": "conciergeAgent",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConciergeChat",
                    "kind": "LinkedField",
                    "name": "chats",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "latestInbound",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "latestOutbound",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c24cec63f1b363c21e10225973c094b",
    "id": null,
    "metadata": {},
    "name": "SetConciergeAgentChatAvailabilityMutation",
    "operationKind": "mutation",
    "text": "mutation SetConciergeAgentChatAvailabilityMutation(\n  $input: SetConciergeAgentAvailabilityInput!\n) {\n  setConciergeAgentChatAvailability(input: $input) {\n    account {\n      conciergeAgent {\n        chats {\n          user {\n            id\n          }\n          id\n        }\n        isAvailable\n        isEnrolledIntoChats\n        ...ConciergeAgentDetailsFragment_conciergeAgent\n      }\n      id\n    }\n  }\n}\n\nfragment ConciergeAgentDetailsFragment_conciergeAgent on ConciergeAgent {\n  isEnrolledIntoChats\n  isAvailable\n  chats {\n    id\n    latestInbound\n    latestOutbound\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b43e3792d7fbe9a5854b391879f3619";

export default node;
