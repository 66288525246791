import { mswGraphql } from '@attentive/mock-data';

export const SetConciergeAgentChatAvailabilityMutationMock = mswGraphql.mutation(
  'SetConciergeAgentChatAvailabilityMutation',
  async (_, res, ctx) => {
    return res(
      ctx.data({
        setConciergeAgentChatAvailability: {
          account: {
            id: 'mock-account-id',
            conciergeAgent: {
              isAvailable: false,
              isEnrolledIntoChats: true,
            },
            firstName: 'Sally',
            lastName: 'Super-User',
          },
        },
      })
    );
  }
);
