import { useMutation } from 'react-relay';

import { ToastType, useToast } from '@attentive/acore-utils';

import SetConciergeAgentChatAvailabilityMutation, {
  SetConciergeAgentChatAvailabilityMutation as SetConciergeAgentChatAvailabilityMutationType,
  SetConciergeAgentChatAvailabilityMutation$data,
} from '../mutations/SetConciergeAgentChatAvailabilityMutation/__generated__/SetConciergeAgentChatAvailabilityMutation.graphql';

export const useSetConciergeAgentChatAvailabilityMutation = () => {
  const [createToast] = useToast();
  const [commit, isInFlight] = useMutation<SetConciergeAgentChatAvailabilityMutationType>(
    SetConciergeAgentChatAvailabilityMutation
  );

  const handleError = () => {
    createToast({
      type: ToastType.Error,
      title: 'Failed to set availability',
      text: 'Please try again',
    });
  };

  const commitSetConciergeAvailabilityMutation = (
    newAvailability: boolean,
    accountId: string,
    onError?: (error: Error) => void,
    onSuccess?: (res: SetConciergeAgentChatAvailabilityMutation$data) => void
  ) => {
    commit({
      variables: {
        input: {
          accountId,
          isAvailable: newAvailability,
        },
      },
      onCompleted: (res) => {
        if (!res.setConciergeAgentChatAvailability?.account?.conciergeAgent) {
          handleError();
          onError?.(new Error('Failed to set agent availability'));
        }
        onSuccess?.(res);
      },
      updater: (store) => {
        const agentRecord = store
          .getRootField('setConciergeAgentChatAvailability')
          ?.getLinkedRecord('conciergeAgent');

        if (!agentRecord) {
          return;
        }
        const accountRecord = store.get(accountId);
        if (accountRecord && agentRecord) {
          accountRecord.setLinkedRecord(agentRecord, 'conciergeAgent');
        }
      },
      onError: (error) => {
        handleError();
        onError?.(error);
      },
    });
  };

  return {
    commitSetConciergeAvailabilityMutation,
    isInFlight,
  };
};
